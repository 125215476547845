.about {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 90vh 100vh;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	@media (max-width: 1000px) {
		grid-template-rows: 90vh auto auto auto;
	}

	&-banner {
		background-image: url('../../Assets/aboutBg.svg');
		background-repeat: no-repeat;
		background-position: 90% 50%;
		@media (max-width: 1000px) {
			background-position: center;
			background-size: 70%;
		}

		&__container {
			width: 52%;
			padding-top: 10%;
			margin-left: 220px;

			@media (max-width: 1000px) {
				margin-left: 0;
				padding: 5%;
				width: 100%;
			}
		}

		&__head {
			width: 100%;
			margin-bottom: 60px;
			@media (max-width: 1000px) {
				margin-bottom: 70%;
			}
			&-top {
				color: #0d2461;
				font-size: 50px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 55px */
				text-transform: uppercase;
				text-align: left;
				span {
					@media (max-width: 1000px) {
						font-size: 40px;
					}
				}
				@media (max-width: 1000px) {
					font-size: 28px;
					text-align: center;
				}
			}
			&-bot {
				color: #0d2461;
				font-size: 50px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 55px */
				text-transform: uppercase;
				text-align: right;
				@media (max-width: 1000px) {
					font-size: 28px;
					text-align: center;
				}
			}
		}

		&__desc {
			display: flex;
			align-items: center;
			&-stick {
				width: 2px;
				height: 105px;
				background: #0d2461;
				margin-right: 20px;
				@media (max-width: 1000px) {
					display: none;
				}
			}

			&-desc {
				color: #0d2461;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 28px */
				@media (max-width: 1000px) {
					font-size: 15px;
				}
			}
		}
	}

	&-goals {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		background: #68e2fd63;

		&__container {
			width: 75%;
			@media (max-width: 1000px) {
				width: 100%;
				padding: 20px;
			}
		}

		&__head {
			color: #0d2461;
			font-size: 45px;
			font-style: normal;
			font-weight: 900;
			line-height: 110%; /* 49.5px */
			margin-bottom: 60px;
			@media (max-width: 1000px) {
				font-size: 32px;
			}
		}

		&__list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);
			grid-column-gap: 20px;
			grid-row-gap: 60px;
			@media (max-width: 1000px) {
				grid-template-columns: repeat(1, 1fr);
				grid-template-rows: repeat(4, 1fr);
			}
			&-item {
				width: 580px;
				height: 240px;
				border-radius: 30px;
				background: #fff;
				display: flex;
				flex-direction: column;
				padding-top: 45px;
				padding-left: 63px;
				@media (max-width: 1000px) {
					width: 100%;
					padding: 40px 45px;
				}

				&:first-child {
					background-image: url('../../Assets/one.svg');
					background-repeat: no-repeat;
					background-position: 20% 110%;
				}
				&:nth-child(2) {
					background-image: url('../../Assets/two.svg');
					background-repeat: no-repeat;
					background-position: 20% 110%;
				}
				&:nth-child(3) {
					background-image: url('../../Assets/three.svg');
					background-repeat: no-repeat;
					background-position: 20% 110%;
				}
				&:nth-child(4) {
					background-image: url('../../Assets/four.svg');
					background-repeat: no-repeat;
					background-position: 20% 110%;
				}

				&-head {
					color: #0d2461;
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					line-height: 130%; /* 23.4px */
					margin-bottom: 20px;
					@media (max-width: 1000px) {
						font-size: 16px;
					}
				}

				&-desc {
					color: #0d2461;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 20.8px */
					opacity: 0.8;
					@media (max-width: 1000px) {
						font-size: 14px;
					}
				}
			}
		}
	}

	&-accredit {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&__container {
			width: 75%;
			margin: 80px 0;
			@media (max-width: 1000px) {
				width: 100%;
				margin: 40px 0;
				padding: 20px 40px;
			}
		}

		&__head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 60px;
			@media (max-width: 1000px) {
				margin-bottom: 40px;
				flex-direction: column;
			}

			&-left {
				color: #0d2461;
				font-size: 45px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 49.5px */
				@media (max-width: 1000px) {
					font-size: 32px;
				}
			}
			&-right {
				color: #0d2461;
				text-align: right;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 18.2px */
				opacity: 0.5;
				@media (max-width: 1000px) {
					font-size: 12px;
				}
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__par {
			p {
				color: #0d2461;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 20.8px */
				margin-bottom: 40px;
				@media (max-width: 1000px) {
					font-size: 14px;
				}
			}
		}

		&__list {
			&-item {
				list-style-type: disc;
				color: #0d2461;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 130%; /* 20.8px */
				opacity: 0.8;
				margin-bottom: 20px;

				p {
					opacity: 0.8;
					color: #0d2461;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%;
					@media (max-width: 1000px) {
						font-size: 14px;
					}
				}
			}
		}
	}

	&-ins {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #d2f6fe;
		&__container {
			width: 75%;
			margin: 80px 0;
			@media (max-width: 1000px) {
				width: 100%;
				margin: 40px 0;
				padding: 20px;
			}
		}

		&__head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 60px;
			@media (max-width: 1000px) {
				margin-bottom: 30px;
				flex-direction: column;
			}

			&-left {
				color: #0d2461;
				font-size: 45px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 49.5px */
				@media (max-width: 1000px) {
					font-size: 32px;
				}
			}
			&-right {
				color: #0d2461;
				text-align: right;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 18.2px */
				opacity: 0.5;
				@media (max-width: 1000px) {
					font-size: 12px;
				}
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__par {
			p {
				color: #0d2461;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 20.8px */
				margin-bottom: 40px;
				@media (max-width: 1000px) {
					font-size: 14px;
				}
			}
		}

		&__list {
			&-item {
				opacity: 0.8;
				color: #0d2461;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%;
				margin-bottom: 20px;

				a {
					opacity: 0.8;
					color: #0d2461;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%;
					@media (max-width: 1000px) {
						font-size: 14px;
					}
					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}
}
