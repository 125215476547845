.header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 23px 20px;
	.container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
