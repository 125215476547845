.home {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
	&-banner {
		width: 100%;
		background-image: url('../../Assets/handsBG.svg');
		background-repeat: no-repeat;
		background-position: 90% 40%;
		height: 90vh;
		@media (max-width: 992px) {
			background-size: contain;
			background-position: 50% 25%;
			height: auto;
		}
		&__container {
			width: 100%;
			height: 100%;
			padding-top: 3%;
			padding-bottom: 3%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__content {
			@media (max-width: 992px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
			}
		}

		&__head {
			display: flex;
			flex-direction: column;
			@media (max-width: 992px) {
				align-items: center;
				justify-content: center;
			}
			&-top {
				color: #0d2461;
				font-size: 50px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 55px */
				margin-left: 120px;
				@media (max-width: 992px) {
					display: flex;
					flex-direction: column;
					text-transform: lowercase;
					text-align: center;
					span {
						font-size: 45px;
						text-transform: uppercase;
					}
					margin-left: 0;
					font-size: 28px;
				}
			}

			&-bot {
				color: #0d2461;
				font-size: 50px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 55px */
				margin-left: 390px;
				margin-bottom: 8px;
				@media (max-width: 992px) {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;

					text-transform: lowercase;
					margin-left: 0;
					font-size: 28px;
				}
			}

			&-par {
				color: #0d2461;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 25.2px */
				opacity: 0.8;
				margin-left: 390px;
				z-index: 1;
				@media (max-width: 992px) {
					margin-left: 0;
					text-align: center;
					font-size: 14px;
					margin-bottom: 90%;
				}
			}
		}

		&__info {
			margin-top: 4%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-left: 8%;
			@media (max-width: 992px) {
				margin-left: 0;
				padding: 0px 1%;
			}
			&-stick {
				width: 2px;
				height: 75px;
				background: #0d2461;
				margin-right: 20px;
				@media (max-width: 992px) {
					display: none;
				}
			}

			&-desc {
				color: #0d2461;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 28px */
				@media (max-width: 992px) {
					font-size: 15px;
					text-align: center;
				}
			}
		}

		&__btn {
			margin-top: 5%;
			margin-left: 390px;

			@media (max-width: 992px) {
				margin-left: 0;
				margin-top: 5%;
				margin-bottom: 10%;
				width: 200px;
				height: 50px;
				flex-shrink: 0;
			}
		}

		&__list {
			display: flex;
			align-items: center;
			width: 90%;
			margin: 3% auto 0;
			justify-content: space-around;
			@media (max-width: 992px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, 1fr);
				grid-column-gap: 0px;
				grid-row-gap: 0px;
			}
			&-item {
				display: flex;

				svg {
					@media (max-width: 992px) {
						width: 24px;
					}
				}
				&-par {
					color: #0d2461;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 20.8px */
					margin-left: 10px;
					@media (max-width: 992px) {
						font-size: 12px;
					}
				}
			}
		}
	}

	&-news {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #68e2fd70;
		// opacity: 0.3;

		&__container {
			width: 85%;
			display: flex;
			flex-direction: column;
			margin-top: 80px;
		}

		&__head {
			margin-bottom: 60px;
			@media (max-width: 992px) {
				margin-bottom: 40px;
			}
			&-head {
				color: #0d2461;
				font-size: 45px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 49.5px */
				@media (max-width: 992px) {
					font-size: 32px;
				}
			}
		}

		&__news-cards {
			display: flex;
			justify-content: space-between;
			margin-bottom: 70px;
			@media (max-width: 992px) {
				flex-direction: column;
				margin-bottom: 0;
			}
		}

		&__more {
			margin-bottom: 80px;
			display: flex;
			align-items: center;
			justify-content: center;

			a {
				align-items: center;
				display: flex;
				color: #0d2461;
				font-family: Inter;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 28px */
				@media (max-width: 992px) {
					font-size: 14px;
				}

				svg {
					margin-left: 10px;
					@media (max-width: 992px) {
						font-size: 5px;
						width: 14px;
					}
				}
			}
		}
	}

	&-members {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&__container {
			width: 85%;
		}

		&__head {
			margin-top: 80px;
			&-head {
				color: #0d2461;
				font-size: 45px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 49.5px */
				@media (max-width: 992px) {
					text-align: center;
					font-family: Inter;
					font-size: 32px;
				}
			}
		}

		&__cards {
			margin-top: 60px;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(2, 1fr);
			grid-column-gap: 5%;
			grid-row-gap: 40px;
			@media (max-width: 992px) {
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(4, 1fr);
			}
		}

		&__btn {
			margin-top: 50px;
			margin-bottom: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
