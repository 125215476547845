.footer {
	width: 100%;
	border: 1px solid #000;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #0d2461;

	&__container {
		margin: 80px 10%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		@media (max-width: 992px) {
			margin: 60px 0;
		}
	}

	&__content {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		@media (max-width: 992px) {
			flex-direction: column;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		width: 50%;
		&_head {
			color: #fff;
			font-size: 45px;
			font-style: normal;
			font-weight: 900;
			line-height: 110%; /* 49.5px */
			margin-bottom: 50px;
			@media (max-width: 992px) {
				font-size: 32px;
			}
		}

		&_item {
			display: flex;
			margin-bottom: 50px;
			@media (max-width: 992px) {
				flex-direction: column;

				margin-bottom: 20px;
			}
			&_head {
				color: #fff;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: 130%; /* 23.4px */
				display: inline-block;
				width: 50%;
				@media (max-width: 992px) {
					width: 100%;
				}
			}
			&_desc {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: 50%;
				@media (max-width: 992px) {
					width: 100%;
				}
				p,
				a {
					color: #fff;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 20.8px */
					opacity: 0.8;
					margin-bottom: 5px;
				}
			}
		}
	}

	&__nav {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 350px;
	}

	&__logo {
		margin-bottom: 50px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		@media (max-width: 992px) {
			align-items: center;
		}

		&_item {
			margin-bottom: 15px;
			a {
				color: #fff;
				text-align: right;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 20.8px */
			}
		}
	}
	&__copyright {
		&_par {
			color: #fff;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 140%; /* 22.4px */
			opacity: 0.5;
		}
	}
}
