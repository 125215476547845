.header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.container {
		width: 100%;
		margin: 15px 40px;
	}

	&__nav {
		display: flex;
		justify-content: space-between;
		margin: 0px 10px;
		align-items: center;
	}

	&__logo {
	}

	&__menu {
		&_list {
			display: flex;
			justify-content: center;

			a {
				color: #0d2461;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 20.8px */
				margin: 0px 15px;
			}
		}
	}
}
