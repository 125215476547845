.overlay {
	width: 100vw;
	height: 100vh;

	background-color: #0000005d;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 998;
	transition: all 1s ease-in-out;
	.form {
		transform: scale(1);
		animation: scale 0.3s ease-in-out 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 30px;
		background: #d2f6fe;
		padding: 45px 40px;
		position: absolute;
		left: 50%;
		top: 50%;

		margin-left: calc(350px / 2 * -1);
		margin-top: calc(331px / 2 * -1);
		z-index: 999;

		.closeModal {
			position: absolute;
			top: 0;
			right: 0;
			margin-right: 20px;
			margin-top: 15px;
			transition: transform 500ms ease;
			&:hover {
				transform: scale(1.2);
			}

			&:active {
				transform: scale(0.9);
			}
		}
		form {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			input {
				border-radius: 10px;
				background: #fff;
				padding: 15px;
				margin-bottom: 20px;
				transition: box-shadow 0.5s ease-in-out, transform 0.2s ease-in;
				&:hover {
					box-shadow: 0 0 5px 1px #0d2461;
				}

				&:focus {
					transform: scaleX(1.01);
					box-shadow: 0 0 5px 2px #0d2461;
				}
			}

			.btn {
				margin-top: 20px;
				margin-bottom: 10px;
			}
			.btn {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 247px;
				height: 55px;
				border-radius: 10px;
				background: #0d2461;
				color: #fff;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 100%; /* 16px */
				transition: transform 0.3s ease-in-out, color 0.1s ease-in-out, box-shadow 0.2s ease,
					background-color 0.3s ease-in-out;
				&:hover {
					transform: scale(1.01);
					background-color: #fff;
					color: #0d2461;
					box-shadow: 0 0 3px 3px #0d2461;
				}
			}
		}
		&_copy {
			color: #0d2461;
			text-align: center;
			opacity: 0.8;
			font-size: 10px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%; /* 13px */
		}
	}
}
@keyframes scale {
	from {
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
}
