.newsCard {
	width: 373px;
	height: 550px;
	@media (max-width: 992px) {
		width: 100%;
		margin-bottom: 9rem;
		height: calc(550px - 5rem);
	}
	.banner {
		background: linear-gradient(139deg, #0ff 0%, #0696fa 100%);
		height: 40%;
		width: 100%;
		border-radius: 30px 30px 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		&__head {
			color: #fff;
			font-size: 20px;
			font-style: normal;
			font-weight: 900;
			line-height: 100%; /* 20px */
		}
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: center;

		border-radius: 0 0 30px 30px;
		padding: 50px 0;

		&__container {
			width: 70%;
			@media (max-width: 1000px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}

		&__head {
			color: #0d2461;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 130%; /* 23.4px */
			margin-bottom: 20px;
			@media (max-width: 992px) {
				font-size: 16px;
			}
		}

		&__desc {
			color: #0d2461;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%; /* 20.8px */
			opacity: 0.8;
			margin-bottom: 30px;
			@media (max-width: 992px) {
				font-size: 14px;
			}
		}

		&__btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 270px;
			height: 50px;
			border-radius: 10px;
			background: #0d2461;
			color: #fff;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%; /* 16px */
			transition: transform 0.3s ease-in-out, color 0.1s ease-in-out, box-shadow 0.2s ease,
				background-color 0.3s ease-in-out;
			&:hover {
				transform: scale(1.01);
				background-color: #fff;
				color: #0d2461;
				box-shadow: 0 0 3px 3px #0d2461;
			}
			@media (max-width: 992px) {
				width: 200px;
				height: 50px;
				font-size: 14px;
			}
		}
	}
}
