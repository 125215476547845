.newspage {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 100px 0;
	@media (max-width: 1000px) {
		padding: 20px;
	}
	&__container {
		width: 75%;
		@media (max-width: 1000px) {
			width: 95%;
		}
	}

	&__head {
		border-radius: 10px;
		background: linear-gradient(115deg, #0ff 8.61%, #0696fa 97.05%);
		margin-bottom: 60px;
		&-head {
			color: #0d2461;
			font-size: 50px;
			font-style: normal;
			font-weight: 900;
			line-height: 110%; /* 55px */
			text-transform: uppercase;
			padding: 1em 1em;
			@media (max-width: 1000px) {
				font-size: 32px;
			}
		}
	}

	&__content {
		display: flex;
		justify-content: space-between;
		width: 100%;
		@media (max-width: 1000px) {
			flex-direction: column-reverse;
		}
	}
	&__text {
		width: 50%;
		@media (max-width: 1000px) {
			width: 100%;
		}
		&-text {
			color: #0d2461;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%; /* 20.8px */
			opacity: 0.8;
			@media (max-width: 1000px) {
				font-size: 14px;
			}
			span {
				opacity: 0.8;
				color: #0d2461;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 130%;
				@media (max-width: 1000px) {
					font-size: 14px;
				}
			}
		}
	}

	&__nav {
		border-radius: 10px;
		background: #d2f6fe;
		height: 361px;
		padding: 45px 64px;
		@media (max-width: 1000px) {
			height: auto;
			padding: 20px 40px;
			margin-bottom: 20px;
		}
		&-list {
			&-head {
				color: #0d2461;
				font-family: Inter;
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: 130%; /* 23.4px */
				@media (max-width: 1000px) {
					font-size: 16px;
				}
			}

			&-item {
				color: #0d2461;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 20.8px */
				margin-top: 20px;
				@media (max-width: 1000px) {
					font-size: 14px;
				}
			}
		}
	}
}
