.doc {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 80px 0;

	&__container {
		width: 75%;
		@media (max-width: 1000px) {
			width: 95%;
		}
	}

	&__head {
		color: #0d2461;
		font-size: 50px;
		font-style: normal;
		font-weight: 900;
		line-height: 110%; /* 55px */
		text-transform: uppercase;
		margin-bottom: 60px;
		@media (max-width: 1000px) {
			font-size: 32px;
			padding: 0px 20px;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(6, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 20px;
		@media (max-width: 1000px) {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(12, 1fr);
		}

		&-item {
			@media (max-width: 1000px) {
				padding: 0px 20px;
			}
			&-head {
				a {
					color: #0d2461;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 20.8px */
					@media (max-width: 1000px) {
						font-size: 14px;
					}
				}
			}

			&-desc {
				color: #0d2461;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 18.2px */
				opacity: 0.5;
				@media (max-width: 1000px) {
					font-size: 12px;
				}
			}
		}
	}
}
