.cont {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	&__container {
		width: 50%;
		margin-left: 10%;
		margin-top: 100px;
		margin-bottom: 100px;
		@media (max-width: 1000px) {
			width: 95%;
			margin-top: 10%;
			margin-left: 0;
			padding: 0px 20px;
		}
	}

	&__head {
		color: #0d2461;
		font-size: 50px;
		font-style: normal;
		font-weight: 900;
		line-height: 110%; /* 55px */
		text-transform: uppercase;
		margin-bottom: 60px;
		@media (max-width: 1000px) {
			font-size: 32px;
		}
	}

	&__main {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(3, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 50px;
		@media (max-width: 1000px) {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(6, 1fr);
			grid-row-gap: 10px;
		}

		&-head {
			color: #0d2461;
			font-family: Inter;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 130%; /* 23.4px */
			@media (max-width: 1000px) {
				font-size: 16px;
			}
		}

		&-desc {
			display: flex;
			flex-direction: column;
			p,
			a {
				color: #0d2461;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 20.8px */
				opacity: 0.8;
				@media (max-width: 1000px) {
					font-size: 14px;
				}
			}
		}
	}
}
