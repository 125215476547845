.menu {
	width: 100%;
	height: 100vh;
	position: fixed;
	right: 0;
	overflow: hidden;
	background-color: white;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
	.container {
		height: 70%;
		.list {
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			.item {
				color: #0d2461;
				text-align: center;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 100%; /* 14px */
				margin-bottom: 40px;
			}
		}
	}
}
.menu_close {
	width: 100%;
	height: 100vh;
	position: fixed;
	right: -500px;
	overflow: hidden;
	background-color: white;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
	.container {
		height: 70%;
		.list {
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			.item {
				color: #0d2461;
				text-align: center;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 100%; /* 14px */
				margin-bottom: 40px;
			}
		}
	}
}
