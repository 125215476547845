@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
	font-family: 'Inter', sans-serif;
}

.wrapper {
	width: 100%;
	background-color: #fff;
}

.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 247px;
	height: 55px;
	border-radius: 10px;
	background: #0d2461;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 100%; /* 16px */
	transition: transform 0.3s ease-in-out, color 0.1s ease-in-out, box-shadow 0.2s ease,
		background-color 0.3s ease-in-out;
	&:hover {
		transform: scale(1.01);
		background-color: #fff;
		color: #0d2461;
		box-shadow: 0 0 3px 3px #0d2461;
	}
}
