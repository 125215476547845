.news {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 120px 0;
	@media (max-width: 1000px) {
		padding: 60px 0;
	}

	&__container {
		width: 90%;
		@media (max-width: 1000px) {
			width: 100%;
		}
	}

	&__head {
		color: #0d2461;
		font-size: 50px;
		font-style: normal;
		font-weight: 900;
		line-height: 110%; /* 55px */
		text-transform: uppercase;
		margin-bottom: 60px;
		margin-left: 4%;
		@media (max-width: 1000px) {
			margin: 0;
			font-size: 45px;
			margin-bottom: 60px;
		}
	}

	&__news {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(3, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 60px;
		justify-items: center;
		margin-bottom: 80px;
		@media (max-width: 1000px) {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(6, 1fr);
			margin-bottom: 0;
			padding: 20px;
		}
	}

	&__more {
		display: flex;
		align-items: center;
		justify-content: center;
		a {
			color: #0d2461;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 140%; /* 28px */
			svg {
				margin-left: 10px;
			}
		}
	}
}
