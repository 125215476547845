.members {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 88vh auto auto auto;
	grid-column-gap: 0px;
	grid-row-gap: 0px;

	&-banner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		background-image: url('../../Assets/membersBG.svg');
		background-repeat: no-repeat;
		background-position: 80% 60%;
		@media (max-width: 1000px) {
			background-size: 80%;
			background-position: center center;
		}

		&__container {
			width: 60%;
			margin-left: 10%;
			@media (max-width: 1000px) {
				width: 100%;
				margin-left: 0;
				padding: 20px;
			}
		}

		&__head {
			width: 100%;

			&-top {
				color: #0d2461;
				font-size: 50px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 55px */
				text-transform: uppercase;
				span {
					@media (max-width: 1000px) {
						font-size: 45px;
						text-align: center;
						width: 100%;
						display: block;
					}
				}
				@media (max-width: 1000px) {
					font-size: 28px;
					text-align: center;
				}
			}

			&-bot {
				color: #0d2461;
				font-size: 50px;
				font-style: normal;
				font-weight: 900;
				line-height: 110%; /* 55px */
				text-transform: uppercase;
				text-align: right;
				margin-right: 80px;
				@media (max-width: 1000px) {
					font-size: 28px;
					margin-right: 0;
					text-align: center;
				}
			}
		}

		&__desc {
			margin-top: 60px;
			margin-bottom: 60px;
			display: flex;
			align-items: center;
			&-stick {
				width: 2px;
				height: 105px;
				background: #0d2461;
				margin-right: 10px;
				@media (max-width: 1000px) {
					display: none;
				}
			}
			&-text {
				color: #0d2461;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 28px */
				@media (max-width: 1000px) {
					font-size: 16px;
				}
			}
		}

		&__btn {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;
		}
	}

	&-rights {
		background: #68e2fd67;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding-bottom: 75px;
		padding-top: 80px;

		&__info {
			position: absolute;
			top: 0;
			right: 150px;
			border-radius: 0px 0px 30px 30px;
			background: #0d2461;
			padding: 45px 63px;
			@media (max-width: 1000px) {
				width: 80%;
				position: static;
				padding: 20px 52px;
				border-radius: 0px 20px 20px 0px;
				margin-bottom: 50px;
			}

			&-text {
				color: #fff;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 20.8px */
				@media (max-width: 1000px) {
					font-size: 14px;
				}
				span {
					font-weight: 700;
				}
			}
		}

		&__container {
			width: 75%;
			@media (max-width: 1000px) {
				width: 100%;
			}
		}

		&__head {
			color: #0d2461;
			font-size: 45px;
			font-style: normal;
			font-weight: 900;
			line-height: 110%; /* 49.5px */
			margin-bottom: 60px;
			@media (max-width: 1000px) {
				font-size: 32px;
				padding: 20px;
				margin-bottom: 30px;
			}
		}
		&__desc {
			color: #0d2461;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%; /* 20.8px */
			margin-bottom: 40px;
			@media (max-width: 1000px) {
				font-size: 14px;
				padding: 20px;
				margin: 0;
			}
		}

		&__content {
			display: flex;
			justify-content: space-between;
			@media (max-width: 1000px) {
				flex-direction: column;
				padding: 20px;
			}
			&-left {
				padding: 10px;
			}
			&-right {
				padding: 10px;
			}

			&-link {
				margin-top: 160px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@media (max-width: 1000px) {
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
				a {
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #0d2461;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 140%; /* 28px */
					@media (max-width: 1000px) {
						font-size: 14px;
						margin-bottom: 20px;
					}
					svg {
						margin-left: 10px;
					}
				}
			}
			&-info {
				p {
					color: #0d2461;
					text-align: right;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 18.2px */
					opacity: 0.5;
					@media (max-width: 1000px) {
						font-size: 12px;
					}
				}
				margin-left: 50px;
				@media (max-width: 1000px) {
					margin: 0;
				}
			}

			&-list {
				span {
					color: #0d2461;
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 130%; /* 20.8px */
					opacity: 0.8;
					@media (max-width: 1000px) {
						font-size: 14px;
					}
				}

				&-item {
					opacity: 0.8;
					color: #0d2461;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%;
					list-style-type: disc;
					margin-top: 5px;
					@media (max-width: 1000px) {
						font-size: 14px;
					}
				}
			}
		}
	}

	&-form {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&__container {
			width: 75%;
			margin: 80px auto;
			@media (max-width: 1000px) {
				width: 100%;
				padding: 20px;
			}
		}

		&__head {
			color: #0d2461;
			font-size: 45px;
			font-style: normal;
			font-weight: 900;
			line-height: 110%; /* 49.5px */
			margin-bottom: 60px;
			@media (max-width: 1000px) {
				font-size: 32px;
				text-align: center;
			}
		}

		&__content {
			width: 100%;
			display: flex;
			justify-content: space-between;
			@media (max-width: 1000px) {
				flex-direction: column;
			}
			&-form {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				border-radius: 30px;
				background: #d2f6fe;
				padding: 45px 40px;

				form {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					input {
						border-radius: 10px;
						background: #fff;
						padding: 15px;
						margin-bottom: 20px;
						transition: box-shadow 0.5s ease-in-out, transform 0.2s ease-in;
						&:hover {
							box-shadow: 0 0 5px 1px #0d2461;
						}

						&:focus {
							transform: scaleX(1.01);
							box-shadow: 0 0 5px 2px #0d2461;
						}
					}
				}
				&-btn {
					margin-top: 20px;
					margin-bottom: 10px;
				}
				&-btn {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 247px;
					height: 55px;
					border-radius: 10px;
					background: #0d2461;
					color: #fff;
					text-align: center;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 100%; /* 16px */
					transition: transform 0.3s ease-in-out, color 0.1s ease-in-out, box-shadow 0.2s ease,
						background-color 0.3s ease-in-out;
					&:hover {
						transform: scale(1.01);
						background-color: #fff;
						color: #0d2461;
						box-shadow: 0 0 3px 3px #0d2461;
					}
				}
				&-copy {
					color: #0d2461;
					text-align: center;
					opacity: 0.8;
					font-size: 10px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 13px */
				}
			}
			&-left {
				@media (max-width: 1000px) {
					margin-bottom: 50px;
				}
			}
			&-right {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
			}

			&-list {
				&-item {
					color: #0d2461;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 20.8px */
					margin-bottom: 5px;
				}
			}
		}
	}

	&-reestr {
		background: #68e2fd59;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 80px 0;

		&__container {
			width: 75%;
		}

		&__head {
			color: #0d2461;
			font-size: 50px;
			font-style: normal;
			font-weight: 900;
			line-height: 110%; /* 55px */
			text-transform: uppercase;
			margin-bottom: 60px;
			@media (max-width: 1000px) {
				font-size: 32px;
			}
		}

		&__list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(4, 1fr);
			grid-column-gap: 5%;
			grid-row-gap: 0px;
			@media (max-width: 1000px) {
				grid-template-columns: repeat(1, 1fr);
				grid-template-rows: repeat(8, 1fr);
			}

			&-item {
				margin-bottom: 20px;
				&-head {
					color: #0d2461;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 20.8px */
				}

				&-par {
					color: #0d2461;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 18.2px */
					opacity: 0.5;

					a {
						opacity: 0.5;
						color: #0d2461;
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 130%;

						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
